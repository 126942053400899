<template>
  <div class="attendance_wrapper">
    <div class="titleBar" v-if="isApp"></div>

    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>考勤信息</span>
      </div>
    </div>

    <div class="attendance_select">
      <div class="select_box">
        <!-- <span class="icon icon-switch"
          ><span class="path1"></span><span class="path2"></span
          ><span class="path3"></span
        ></span> -->
        {{ stationName }}
      </div>
      <div class="select_time" @click="showDatePicker = true">
        {{
          `${calendarOptions.params.curYear} 年 ${calendarOptions.params
            .curMonth + 1} 月`
        }}
      </div>
    </div>

    <div class="calendar_box">
      <calendar
        :calendar="calendarOptions"
        :events="events"
        :shifts="shifts"
        :father="'attendance'"
        @onClickDate="onClickDate"
      ></calendar>
    </div>

    <div class="calendar_bottom">
      <div class="type_box">
        <div class="type_item">
          <div class="item_icon normal"></div>
          <div class="item_txt">正常</div>
        </div>

        <div class="type_item">
          <div class="item_icon abnormal"></div>
          <div class="item_txt">异常</div>
        </div>

        <div class="type_item">
          <div class="item_icon absenteeism"></div>
          <div class="item_txt">缺勤</div>
        </div>
      </div>

      <div class="attendance_box" v-if="dateDetail">
        <div class="attendance_title">
          {{ dateDetail.date | dateFilter_YY_MM_DD(2) }}
        </div>
        <div class="attendance_content">
          <div
            class="attendance_item"
            v-for="(v, i) in dateDetail.attendance"
            :key="i"
          >
            <div class="item_shift">{{ v.name || "---" }}</div>
            <div class="item_name">{{ v.employeeName || "---" }}</div>
            <div
              class="item_startTime"
              :style="{
                color: v.clockState == 2 || v.clockState == 4 ? 'red' : '#333'
              }"
            >
              {{ v.clockInTime || "- - : - -" }}
            </div>
            <div
              class="item_endTime"
              :style="{
                color: v.clockState == 3 || v.clockState == 4 ? 'red' : '#333'
              }"
            >
              {{ v.clockOutTime || "- - : - -" }}
            </div>
          </div>

          <!-- <div class="attendance_item">
            <div class="item_shift">夜班</div>
            <div class="item_name">{{ dateDetail.nightCashier || "---" }}</div>
            <div class="item_startTime">
              {{ dateDetail.nightStartTime || "- - : - -" }}
            </div>
            <div class="item_endTime">
              {{ dateDetail.nightEndTime || "- - : - -" }}
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <van-popup v-model="showDatePicker" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @cancel="showDatePicker = false"
        @confirm="onDateConfirm"
      />
    </van-popup>

    <!-- <div class="calendar_bottom_box"></div> -->
  </div>
</template>

<script>
let newDate = new Date();
import { mapState } from "vuex";
import calendar from "../../components/Calendar";
export default {
  components: {
    calendar
  },
  data: () => ({
    showDatePicker: false,
    currentDate: newDate,
    minDate: new Date(newDate.getFullYear(), newDate.getMonth() - 12, 1),
    maxDate: newDate,
    events: [],
    shifts: 2,
    dateDetail: null,
    stationName: ""
  }),
  methods: {
    onClickDate(item) {
      if (item.attendance.length > 0) {
        console.log(item);
        this.dateDetail = item;
      }
    },
    formatter(type, val) {
      if (type === "month") {
        return `${val}月`;
      } else if (type === "year") {
        return `${val}年`;
      }
      return val;
    },
    onDateConfirm(value) {
      this.currentDate = value;
      this.showDatePicker = false;

      this.initData();
    },

    initData() {
      let date = new Date(this.currentDate);
      let stationId = this.$route.query.stationId;
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      // this.$get(this.$config.TL_TEAM_INFO_GET_ATTENDANCE_INFO, {
      //   stationId,
      //   time: `${date.getFullYear()}-${month}-01`,
      // })
      this.$api
        .getAttendanceInfo({
          stationId,
          time: `${date.getFullYear()}-${month}-01`
        })
        .then(res => {
          console.log(res);
          this.events = res["data"]["data"];
        });
    }
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp
    }),

    calendarOptions() {
      let dateObj = this.currentDate;
      return {
        options: {
          weekStartOn: 0
        },
        params: {
          curYear: dateObj.getFullYear(),
          curMonth: dateObj.getMonth(),
          curDate: dateObj.getDate(),
          curEventsDate: "all"
        }
      };
    }
  },

  created() {
    this.stationName = this.$route.query.stationName;
    this.initData();

    this.$tools.entering();
  }
};
</script>

<style lang="less" scoped>
.attendance_wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.attendance_select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 10px;
  border-bottom: 1px solid rgba(7, 17, 27, 0.1);

  .select_box {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;

    .icon {
      font-size: 20px;
    }
  }

  .select_time {
    font-size: 18px;
    color: #4f545f;
  }
}

.calendar_box {
  flex: 1;
  overflow: scroll;
  width: 104%;
  padding: 0 2%;
  transform: translateX(-2%);
}

.calendar_bottom {
  width: 100%;
  min-height: 160px;
  background-color: #fff;
  border-top: 1px solid rgba(7, 17, 27, 0.1);
  // box-shadow: 0px 2px 4px #ccc inset;
}

.calendar_bottom_box {
  width: 100%;
  height: 160px;
}

.type_box {
  display: flex;
  justify-content: flex-end;
  padding: 14px;

  .type_item {
    margin-right: 20px;
    display: flex;
    align-items: center;
    font-size: 12px;

    &:last-child {
      margin: 0;
    }

    .item_icon {
      height: 16px;
      width: 40px;
      border-radius: 10px;
      background-color: #ccc;
      margin-right: 10px;

      &.normal {
        background-color: #40c057;
      }

      &.abnormal {
        background-color: #ff9900;
      }

      &.absenteeism {
        background-color: #a1a1a1;
      }
    }
  }
}

.attendance_box {
  padding: 0 20px 20px;
  font-size: 16px;

  .attendance_title {
    margin-bottom: 14px;
  }

  .attendance_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    &:last-child {
      margin: 0;
    }

    > div {
      flex: 1;
      // text-align : center ;
    }
  }
}
</style>
